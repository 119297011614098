import { useEffect, useSyncExternalStore } from 'react'
import {
  EVENT_QUEUE_KEY,
  FIRST_TRIGGERED_KEY,
  STORAGE_EVENT_KEY,
} from './utils'

type DebounceHook = (
  queue: string,
  action: () => Promise<void>,
  delay: number
) => void

export const useDebounceEvent: DebounceHook = (queue, action, delay) => {
  // Must parse what we receive from useSyncExternalStore because it's stringified (if we store data as plain objects, it does trigger an infinite loop)
  const parsedQueue = JSON.parse(queue)
  // const firstTriggerRef = useRef<boolean | undefined>()
  const isFirstTriggered =
    typeof window !== 'undefined'
      ? sessionStorage?.getItem(FIRST_TRIGGERED_KEY)
      : false

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (typeof window !== 'undefined') {
      if (!isFirstTriggered) {
        if (parsedQueue.length > 0) {
          action()
          // firstTriggerRef.current = true
          sessionStorage.setItem(FIRST_TRIGGERED_KEY, 'true')
        }
      } else {
        if (parsedQueue.length > 0) {
          timeout = setTimeout(() => {
            action()
          }, delay)
        }
      }
    }

    return () => clearTimeout(timeout)
  }, [queue, action])
}

// Callbacks to set up the useSyncExternalStore hook
const subscribe = (callback: () => void) => {
  window.addEventListener(STORAGE_EVENT_KEY, callback)
  return () => window.removeEventListener(STORAGE_EVENT_KEY, callback)
}
const getQueue = () => localStorage.getItem(EVENT_QUEUE_KEY) || '[]'
const getServerQueue = () => '[]' // Since we inserted the component in gatsby.server as well, we also need the server snapshot

export const useEventQueue = () => {
  const queue = useSyncExternalStore(subscribe, getQueue, getServerQueue)
  return queue
}
