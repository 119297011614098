import { Script } from 'gatsby'

const getUrl = (url: string) => {
  const regex = new RegExp('\\/ids\\/(\\d+)\\/')
  const idUrl = url.match(regex)
  let newUrlproductImages = ''

  if (idUrl) {
    const [, id] = idUrl
    const newId = `${id}-200-200`

    newUrlproductImages = url.replace(id, newId)
  }

  return newUrlproductImages
}

const configs = `window.__clerk_q=window.__clerk_q||[];
  window.Clerk=window.Clerk||function(){
    window.__clerk_q.push(arguments)
  };

  Clerk('config', {
    key: "HTpVSHMcjuu65SL9byhsjpHk6YxiPZdX",
    formatters: {
      get_url: ${getUrl}
    }
  });`

const ClerkioScript = () => {
  return (
    <>
      <Script
        id="script-clerkio-carousel"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: configs,
        }}
      />
      <Script
        src={`${
          document.location.protocol == 'https:' ? 'https' : 'http'
        }://cdn.clerk.io/clerk.js`}
      />
    </>
  )
}

export default ClerkioScript
