import { PageTypes } from 'src/typings/pageTypes'

export const getPageType = (page: string) => {
  let result: PageTypes = PageTypes.NotSet

  switch (page) {
    case 'component---src-pages-slug-tsx':
    case 'component---src-templates-plp-tsx':
      result = PageTypes.PLP
      break
    case 'component---src-pages-lp-tsx':
      result = PageTypes.InstitutionalPage
      break
    case 'component---src-pages-index-tsx':
      result = PageTypes.Home
      break
    case 'component---src-pages-[slug]-p-tsx':
      result = PageTypes.PDP
      break
    case 'component---src-pages-login-tsx':
      result = PageTypes.Login
      break
    case 'component---src-pages-logout-tsx':
      result = PageTypes.Logout
      break
    case 'component---src-pages-checkout-tsx':
      result = PageTypes.Checkout
      break
    case 'component---src-pages-s-tsx':
      result = PageTypes.Search
      break
    default:
      result = PageTypes.NotSet
      break
  }

  return result
}
