import { useCallback, useEffect } from 'react'
import { useDebounceEvent, useEventQueue } from './hooks'
import {
  DELAY,
  EVENT_QUEUE_KEY,
  notifyUpdate,
  STORAGE_EVENT_KEY,
  UPDATED_CART_EVENT_KEY,
} from './utils'
import { useSession } from '@faststore/sdk'
import { useCart } from 'src/sdk/cart/useCart'
// Typings
type Props = {
  children: React.ReactNode
}

const AbandonedCartEventHandler = ({ children }: Props) => {
  // The component subscribes to changes in localStorage, where we store the "update cart" notifications
  const queue = useEventQueue()
  const { id: orderFormId } = useCart()
  const { person } = useSession()

  const eventHandler = useCallback((e: CustomEvent) => {
    const eventDetails = e?.detail
    const parsedQueue = JSON.parse(
      localStorage.getItem(EVENT_QUEUE_KEY) || '[]'
    )
    localStorage.setItem(
      EVENT_QUEUE_KEY,
      JSON.stringify([...parsedQueue, eventDetails])
    )
    window.dispatchEvent(new Event(STORAGE_EVENT_KEY))
  }, []) as EventListener

  useEffect(() => {
    window.addEventListener(UPDATED_CART_EVENT_KEY, eventHandler)

    return () =>
      window.removeEventListener(UPDATED_CART_EVENT_KEY, eventHandler)
  }, [])

  useDebounceEvent(queue, () => notifyUpdate(person, orderFormId), DELAY)

  return <>{children}</>
}

export default AbandonedCartEventHandler
