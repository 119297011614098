import { List } from '@faststore/ui'
import { useEffect, useState } from 'react'
import { AdditionalProperty } from 'src/components/sections/NewPDP/typings'
import Button, { ButtonIcon } from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import SlideOver from 'src/components/ui/SlideOver'
import { useCart } from 'src/sdk/cart/useCart'
import { useCheckoutButton } from 'src/sdk/cart/useCheckoutButton'
import { useUI } from 'src/sdk/ui'
import { useModal } from 'src/sdk/ui/modal/Provider'
import { getRealDiscount } from 'src/utils/getRealDiscount'

import CartItem from '../CartItem'
import EmptyCart from '../EmptyCart'
import OrderSummary from '../OrderSummary'
import './cart-sidebar.scss'

// export const getIsPrivateLabel = (item: any) => {
//   let isPrivateLabel: boolean

//   const skuAttributes =
//     item?.itemOffered?.isVariantOf?.additionalProperty?.find(
//       (el: any) => el.name === 'Arcaplanet SKU Attributes'
//     )?.value

//   try {
//     isPrivateLabel =
//       JSON.parse(skuAttributes)[item?.itemOffered?.sku]?.private_label ===
//       'true'
//   } catch (e) {
//     isPrivateLabel = false
//   }

//   return isPrivateLabel
// }

export const calculateLoyaltyPoints = (items: any) => {
  return items.reduce((sum: number, item: any) => {
    const subscriptions = item?.itemOffered?.additionalProperty?.filter(
      (el: AdditionalProperty) =>
        el.valueReference === 'ATTACHMENT' &&
        el.name.includes('vtex.subscription')
    )

    // const isPrivateLabel = getIsPrivateLabel(item)

    let itemLoyaltyPoints = 0

    // if (isPrivateLabel === privateLabel) {
    itemLoyaltyPoints =
      subscriptions?.length > 0
        ? item.price * item.quantity * 0.95
        : item.price * item.quantity
    // }

    return sum + itemLoyaltyPoints
  }, 0)
}

function CartSidebar() {
  const btnProps = useCheckoutButton()
  const cart = useCart()
  const { displayMinicart, closeMinicart } = useUI()
  const { onModalClose } = useModal()

  const { items, isValidating: isCartValidating, total } = cart
  const [productOutOfStockPrice, setProductOutOfStockPrice] = useState(Number)
  const [activeProducts, setActiveProducts] = useState([])
  const [activeProductsQuantity, setActiveProductsQuantity] = useState(Number)
  const [isVTEXValidating, setIsVTEXValidating] = useState(Boolean)

  const [hasOutOfStock, setHasOutOfStock] = useState(Boolean)

  useEffect(() => {
    const cartItemsArray: any[] = []
    const activeProductsArray: any = []
    let activeProductsQuant = 0
    let OutOfStockPrice = 0

    cartItemsArray.push(items)

    cartItemsArray[0].map((item: any) => {
      const itemSku = item?.itemOffered?.sku

      const itemVariantFiltered =
        item?.itemOffered?.isVariantOf?.hasVariant?.filter(
          (i: any) => i?.sku === itemSku
        )

      if (itemVariantFiltered[0]?.offers?.lowPrice <= 0) {
        OutOfStockPrice += item?.price * item?.quantity
      } else {
        const itemQuantity: number = item?.quantity

        activeProductsQuant += itemQuantity
        activeProductsArray.push(item)
      }

      return null
    })

    setProductOutOfStockPrice(OutOfStockPrice)
    // setActiveProducts(activeProductsArray)
    setActiveProducts((prev) => {
      const prevState = JSON.stringify(prev)
      const nextState = JSON.stringify(activeProductsArray)

      if (prevState !== nextState) return activeProductsArray

      return prev
    })
    setActiveProductsQuantity(activeProductsQuant)

    setHasOutOfStock(OutOfStockPrice > 0)
  }, [cart, items, isCartValidating])

  const isEmpty = items.length === 0

  const sumLoyaltyPointsNormal = calculateLoyaltyPoints(items)
  // const sumLoyaltyPointsPrivateLabel = calculateLoyaltyPoints(true, items)
  const totalLoyalPoints = Math.trunc(sumLoyaltyPointsNormal)
  // + Math.trunc(sumLoyaltyPointsPrivateLabel) * 2

  const finalTotal = total - productOutOfStockPrice
  const isUnderPrice = finalTotal < 8.99
  const isDisabled = Boolean(
    isCartValidating || isVTEXValidating || isUnderPrice || btnProps?.isPending
  )

  return (
    <SlideOver
      isOpen={displayMinicart}
      onDismiss={closeMinicart}
      size="full"
      direction="rightSide"
      className="cart-sidebar"
    >
      <header data-testid="cart-sidebar">
        <div className="cart-sidebar__title">
          <p className="text__lead">Il tuo carrello</p>
        </div>
        <ButtonIcon
          data-testid="cart-sidebar-button-close"
          aria-label="Close Cart"
          icon={<Icon name="X" width={24} height={24} />}
          onClick={onModalClose}
        />
      </header>

      {(hasOutOfStock || (isUnderPrice && !isEmpty)) && (
        <div className="outOfStock__slider-message-container">
          <ul>
            {hasOutOfStock && (
              <li>
                Nel carrello è presente un articolo al momento non disponibile.
                <span className="strongText-out"> Rimuovi l'articolo</span> per
                procedere all'acquisto
              </li>
            )}
            {isUnderPrice && !isEmpty && (
              <li>
                Attenzione: il valore minimo d'ordine è di{' '}
                <span className="strongText-out">8,99€</span> (spese di consegna
                escluse)
              </li>
            )}
          </ul>
        </div>
      )}

      {isEmpty ? (
        <EmptyCart onDismiss={onModalClose} />
      ) : (
        <>
          <List
            className={
              isCartValidating || isVTEXValidating
                ? 'cart-sidebar-list-validating'
                : ''
            }
          >
            {isCartValidating || isVTEXValidating ? (
              <div className="loader" />
            ) : null}
            {items.map((item) => (
              <li key={item.id}>
                <CartItem
                  item={item}
                  isCartValidating={Boolean(
                    isCartValidating || isVTEXValidating
                  )}
                  setIsVTEXValidating={setIsVTEXValidating}
                />
              </li>
            ))}
          </List>

          <footer>
            <OrderSummary
              realDiscount={getRealDiscount(activeProducts)}
              subTotal={finalTotal}
              total={finalTotal}
              numberOfItems={activeProductsQuantity}
              checkoutButton={
                <Button
                  variant="primary"
                  {...btnProps}
                  className={`${isUnderPrice ? 'disabledForPrice' : ''}`}
                  disabled={isDisabled}
                >
                  {isCartValidating || isVTEXValidating || btnProps?.isPending
                    ? 'Caricamento in corso...'
                    : 'Vai alla cassa'}
                </Button>
              }
              loyaltyPoints={totalLoyalPoints}
            />
          </footer>
        </>
      )}
    </SlideOver>
  )
}

export default CartSidebar
