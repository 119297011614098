import type { IconProps } from './IconsTypes'

function ReturnsIcon({
  width = 20,
  height = 20,
  color = '#0F0F13',
  viewBox = '0 0 25 25',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3042 9.08022L21.1896 5.17708C20.7917 3.78438 20.3803 2.34375 17.4344 2.34375H7.56454C4.61871 2.34375 4.20623 3.78438 3.80936 5.17708L2.69483 9.08022C2.4615 9.8948 2.34375 10.7375 2.34375 11.5844V18.75C2.34375 21.2687 3.73125 22.6562 6.25 22.6562H18.75C21.2687 22.6562 22.6562 21.2687 22.6562 18.75V11.5833C22.6552 10.7365 22.5375 9.8948 22.3042 9.08022ZM19.6875 5.60624L20.8021 9.50937C20.8135 9.55104 20.8177 9.5948 20.8291 9.63647H13.2802V3.9073H17.4334C19.2022 3.90626 19.324 4.33436 19.6875 5.60624ZM5.31044 5.60624C5.67502 4.33436 5.79684 3.90625 7.56454 3.90625H11.7177V9.63542H4.16883C4.18029 9.59375 4.18341 9.55 4.19591 9.50937L5.31044 5.60624ZM21.0927 18.75C21.0927 20.3927 20.3917 21.0938 18.749 21.0938H6.24898C4.60627 21.0938 3.90523 20.3927 3.90523 18.75V11.5833C3.90523 11.4542 3.92503 11.326 3.93232 11.1979H21.0656C21.0719 11.3271 21.0927 11.4542 21.0927 11.5833V18.75Z"
        fill={color}
      />
      <path
        d="M14.2867 15.126C15.3207 16.195 15.7277 17.69 15.3777 19.126C15.2947 19.469 14.9877 19.698 14.6497 19.698C14.5907 19.698 14.5307 19.691 14.4717 19.677C14.0697 19.579 13.8227 19.173 13.9207 18.771C14.1487 17.838 13.8827 16.866 13.2107 16.17C12.7187 15.663 12.0587 15.39 11.3867 15.377L11.6307 15.622C11.9237 15.915 11.9237 16.39 11.6307 16.683C11.4847 16.829 11.2927 16.903 11.1007 16.903C10.9087 16.903 10.7167 16.83 10.5707 16.683L9.21975 15.332C8.92675 15.039 8.92675 14.564 9.21975 14.271L10.5707 12.919C10.8637 12.626 11.3387 12.626 11.6317 12.919C11.9017 13.189 11.9107 13.607 11.6837 13.901C12.6457 13.984 13.5767 14.396 14.2867 15.126Z"
        fill={color}
      />
    </svg>
  )
}

export default ReturnsIcon
