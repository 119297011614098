import type { SearchEvent } from '@faststore/sdk'
import { sendAnalyticsEvent } from '@faststore/sdk'
import type {
  SearchInputProps as UISearchInputProps,
  SearchInputRef as UISearchInputRef,
} from '@faststore/ui'
import { SearchInput as UISearchInput } from '@faststore/ui'
import type { CSSProperties } from 'react'
import {
  Suspense,
  forwardRef,
  lazy,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import useSearchHistory from 'src/sdk/search/useSearchHistory'
import type { SearchInputContextValue } from 'src/sdk/search/useSearchInput'
import {
  SearchInputProvider,
  formatSearchPath,
} from 'src/sdk/search/useSearchInput'
import useOnClickOutside from 'src/sdk/ui/useOnClickOutside'

import './search-input.scss'

const SearchDropdown = lazy(
  () => import('src/components/search/SearchDropdown')
)

export type SearchInputProps = {
  onSearchClick?: () => void
  buttonTestId?: string
  containerStyle?: CSSProperties
} & Omit<UISearchInputProps, 'onSubmit'>

export type SearchInputRef = UISearchInputRef & { resetSearchInput: () => void }

const sendAnalytics = async (term: string) => {
  sendAnalyticsEvent<SearchEvent>({
    name: 'search',
    params: { search_term: term },
  })
}

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  function SearchInput({ onSearchClick, containerStyle, ...otherProps }, ref) {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const searchQueryDeferred = searchQuery
    const [searchDropdownVisible, setSearchDropdownVisible] =
      useState<boolean>(false)

    const searchRef = useRef<HTMLDivElement>(null)
    const { addToSearchHistory } = useSearchHistory()

    useImperativeHandle(ref, () => ({
      resetSearchInput: () => setSearchQuery(''),
    }))

    const onSearchInputSelection: SearchInputContextValue['onSearchInputSelection'] =
      (term, path) => {
        addToSearchHistory(term)
        sendAnalytics(term)
        setSearchDropdownVisible(false)
        setSearchQuery(term)
        window.location.href = path
      }

    useOnClickOutside(searchRef, () => {
      setSearchDropdownVisible(false)
    })

    return (
      <div
        ref={searchRef}
        data-fs-search-input-wrapper
        data-fs-search-input-dropdown-visible={searchDropdownVisible}
        style={containerStyle}
        className="relative"
      >
        <SearchInputProvider onSearchInputSelection={onSearchInputSelection}>
          <div className="flex flex-col px-4 gap-1 sm:px-0">
            <div className="ui-search-input-container">
              <UISearchInput
                data-fs-search-input
                ref={ref}
                placeholder="Cerca tra oltre 10.000 prodotti"
                onChange={(e: any) => setSearchQuery(e.target.value)}
                onSubmit={(term: string) => {
                  const path = formatSearchPath(term)

                  onSearchInputSelection(term, path)
                }}
                onFocus={() => {
                  setSearchDropdownVisible(true)
                }}
                value={searchQuery}
                {...otherProps}
              />
            </div>

            {searchDropdownVisible && (
              <div className="relative">
                <div
                  className={`fixed z-60 opacity-100 bg-[rgba(0,0,0,0.5)] transition-opacity duration-[0.3s] ease-[ease] inset-0 
                    ${searchDropdownVisible ? 'overlay-visible' : ''}
                  `}
                />
                <Suspense fallback={null}>
                  <div
                    className={`absolute overflow-y-auto max-h-[75vh] w-full p-5 md:pt-6 md:pb-4 md:px-6 shadow-[0_6px_12px_rgba(0,0,0,0.25)] rounded-lg bg-white scrollbar-thin`}
                  >
                    <SearchDropdown
                      term={searchQueryDeferred}
                      closeSearchDropdownEvent={() =>
                        setSearchDropdownVisible(false)
                      }
                    />
                  </div>
                </Suspense>
              </div>
            )}
          </div>
        </SearchInputProvider>
      </div>
    )
  }
)

export default SearchInput
