import { useState } from 'react'
import { useYoutubeThumbnail } from 'src/sdk/ui/useYoutubeThumbnail'

type DescriptionProps = {
  description: string
}

function DescriptionPDP({ description }: DescriptionProps) {
  const [openDescriptionSidebarToggle, setOpenDescriptionSidebarToggle] =
    useState(true)

  const { modifiedHTML } = useYoutubeThumbnail({ html: description })

  const handleOpenSidebar = () => {
    setOpenDescriptionSidebarToggle(!openDescriptionSidebarToggle)
  }

  return (
    <div id="descrizione">
      <div className="w-full max-w-[1520px] mx-auto">
        <div className="px-4 md:px-10 pt-6 md:pt-10">
          <div
            className={`font-semibold flex justify-between items-center text-[#14181F] select-none cursor-pointer ${
              openDescriptionSidebarToggle ? 'sidenav-open' : ''
            }`}
            data-collapse-toggle=""
            onClick={handleOpenSidebar}
          >
            <h2 className="text-base md:text-xl">Descrizione</h2>
            <span className="arca-ico-chevron-down block text-2xl icon-toggler" />
          </div>
          <div className="content-collapse">
            <div className="mt-4 md:mt-6">
              <p
                className="text-black-500 text-sm md:pb-10 font-normal"
                dangerouslySetInnerHTML={{
                  __html: modifiedHTML,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DescriptionPDP
