export const createYoutubeThumbnailElement = (
  width: string,
  height: string,
  src: string | null
) => {
  const container = document.createElement('div')
  container.style.position = 'relative'
  container.style.cursor = 'pointer'
  container.id = 'yt-video'
  container.classList.add('yt-video-div')

  const img = document.createElement('img')
  img.style.position = 'absolute'
  img.style.opacity = '70%'
  img.width = Number(width)
  img.height = Number(height)
  img.src = src ?? ''
  img.id = 'yt-video-img'
  container.appendChild(img)

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('width', '52')
  svg.setAttribute('height', '52')
  svg.setAttribute('viewBox', '0 0 52 52')
  svg.style.position = 'absolute'
  svg.style.top = '50%'
  svg.style.left = '50%'
  svg.style.transform = 'translate(-50%, -50%)'
  svg.id = 'yt-video-svg'
  svg.innerHTML = `
    <circle cx="26" cy="26" r="26" fill="white" fill-opacity="0.8"/>
    <path d="M35.602 24.4761C36.7582 25.2708 36.7582 26.9779 35.602 27.7726L23.4929 36.0954C22.1658 37.0075 20.36 36.0575 20.36 34.4472L20.36 17.8015C20.36 16.1913 22.1658 15.2412 23.4929 16.1533L35.602 24.4761Z" fill="black"/>
  `
  container.appendChild(svg)

  return container
}

export const getYoutubeThumbnail = (src: string | null) => {
  let videoID: string = ''

  if (src) {
    const youtubeUrlFormats = [
      /(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/,
      /(https?:\/\/)?(www\.)?youtu\.be\/([^&=%\?]{11})/,
    ]

    for (let i = 0; i < youtubeUrlFormats.length; i++) {
      let match = src?.match(youtubeUrlFormats[i])
      if (match) {
        videoID = match[6]
        break
      }
    }
  }

  return videoID ? 'https://img.youtube.com/vi/' + videoID + '/0.jpg' : null
}
