import axios from 'axios'
import { FavouriteStore } from 'src/components/sections/Store/typings'

type GeoCoordinatesProp = {
  lat: number
  lng: number
  postalCode?: string
}

export const useStoreAPI = () => {
  const getSellerId = async (id: string | null) => {
    const { data } = await axios.post('/api/store/getSellerIdByStoreId', {
      storeId: id,
    })

    return data?.SellerId
  }

  const getStoreList = async ({ lat, lng }: GeoCoordinatesProp) => {
    const { data } = await axios.post('/api/store/getStoreList', {
      lat: lat,
      lng: lng,
      radius: 100000,
    })

    return data
  }

  const getFavouriteStore = async (email: string) => {
    const { data } = await axios.post('/api/store/getFavouriteStore', {
      email: email,
    })

    return data
  }

  const getStoreDetails = async (id: string) => {
    const { data } = await axios.post('/api/store/getStoreDetails', {
      storeId: id,
    })

    return data
  }

  const getStoreListByProducts = async ({
    skuList,
    seller,
    postalCode,
    quantity,
    geoCoordinates,
  }: any) => {
    const { data } = await axios.post('/api/store/getStoreListByProducts', {
      postalCode: postalCode,
      items: skuList?.map((sku: string) => ({
        id: sku,
        quantity: quantity,
        seller: seller ?? '1',
      })),
      geoCoordinates,
    })

    return data
  }

  const getStoreListBySellerAccount = async ({ skuList, seller }: any) => {
    const { data } = await axios.post(
      '/api/store/getStoreListBySellerAccount',
      {
        sellerId: seller,
        items: skuList?.map((sku: string) => ({
          id: sku,
          quantity: 1,
          seller: '1',
        })),
      }
    )

    return data
  }

  const getPickupPointById = async (id: string) => {
    const { data } = await axios.post('/api/store/getPickupPointById', {
      id: id,
    })
    return data
  }

  const setShippingData = async (orderFormId: string, request: any) => {
    await axios.post('/api/store/setShippingData', {
      orderFormId,
      request: request,
    })
  }

  const saveFavouriteStore = async (email: string, id: string) => {
    await axios.post('/api/store/saveFavouriteStore', {
      email: email,
      storeId: id,
    })
  }

  const getUserPostalCode = async (orderFormId: string) => {
    const { data } = await axios.post('/api/store/getUserPostalCode', {
      orderFormId: orderFormId,
    })

    const postalCode = data?.shippingData?.availableAddresses?.find(
      (el: any) => el?.addressType === 'residential' && el?.country === 'ITA'
    )?.postalCode

    const geoCoordinates = data?.shippingData?.address?.geoCoordinates

    return { postalCode: postalCode, geoCoordinates: geoCoordinates }
  }

  const getPostalCodeFromMaps = async (lat: number, lng: number) => {
    const { data } = await axios.post('/api/store/getPostalCode', {
      lat: lat,
      lng: lng,
    })

    const result = data?.results[0]?.address_components?.filter((el: any) =>
      el.types.includes('postal_code')
    )

    return result?.length > 0 ? result[0]?.long_name : ''
  }

  const savePickupPointDataInSession = async (
    id: string,
    vtexSellerId: string | null
  ) => {
    const pickupPoint = await getPickupPointById(`AGRI${id}`)

    const geoCoordinates = [
      pickupPoint?.address?.location?.longitude,
      pickupPoint?.address?.location?.latitude,
    ]

    const request = {
      clearAddressIfPostalCodeNotFound: false,
      selectedAddresses: [
        {
          addressId: null,
          addressType: 'search',
          city: pickupPoint?.address?.city,
          complement: null,
          country: pickupPoint?.address?.country?.acronym,
          geoCoordinates: geoCoordinates,
          neighborhood: null,
          number: pickupPoint?.address?.number,
          postalCode: pickupPoint?.address?.postalCode,
          receiverName: null,
          reference: null,
          state: pickupPoint?.address?.state,
          street: pickupPoint?.address?.street,
          addressQuery: '',
        },
      ],
      logisticsInfo: [
        {
          itemIndex: 0,
          addressId: null,
          selectedSla: `Store Agrifarma (${vtexSellerId})`,
          selectedDeliveryChannel: 'pickup-in-point',
        },
      ],
    }

    sessionStorage.setItem('favourite_store_pp_data', JSON.stringify(request))
  }

  const setFavouriteStoreInSession = (favouriteStore: FavouriteStore) => {
    sessionStorage.setItem('favourite_store', JSON.stringify(favouriteStore))
  }

  return {
    getSellerId,
    getStoreDetails,
    getFavouriteStore,
    getStoreListByProducts,
    getStoreList,
    saveFavouriteStore,
    getPickupPointById,
    setShippingData,
    getUserPostalCode,
    getPostalCodeFromMaps,
    savePickupPointDataInSession,
    setFavouriteStoreInSession,
    getStoreListBySellerAccount,
  }
}
