type WidgetType =
  | 'mini'
  | 'miniPDP'
  | 'standard'
  | 'carousel'
  | 'horizontalEssential'
  | 'reviews'
  | 'miniTrustPilot'

// common settings for all the widget types
const common = {
  // business unit id
  'data-businessunit-id': '5bd98bd082aa2c0001ccacd0',
  'data-locale': 'it-IT',
  'data-theme': 'light',

  'data-no-reviews': 'show',
}

const widgetSettings = {
  // the horizontal essential widget for the service reviews on the footer
  horizontalEssential: {
    'data-locale': 'it-IT',
    'data-style-alignment': 'left',
    'data-style-height': '28px',
    'data-style-width': '100%',
    'data-template-id': '5406e65db0d04a09e042d5fc',
    'data-businessunit-id': '5bd98bd082aa2c0001ccacd0',
    'data-theme': 'dark',
  },

  // the mini widget for PLPs
  mini: {
    ...common,
    'data-style-alignment': 'left',
    'data-scroll-to-list': 'true',
    'data-template-id': '54d39695764ea907c0f34825',
    'data-style-height': '24px',
    'data-style-width': '220px',
  },
  miniNewPDP: {
    ...common,
    'data-style-alignment': 'left',
    'data-scroll-to-list': 'true',
    'data-template-id': '54d39695764ea907c0f34825',
    'data-style-height': '24px',
    'data-style-width': '320px',
  },
  miniTrustPilot: {
    ...common,
    'data-style-alignment': 'left',
    'data-scroll-to-list': 'true',
    'data-template-id': '53aa8807dec7e10d38f59f32',
    'data-style-height': '120px',
    'data-style-width': '220px',
  },
  // the mini widget for PDPs
  miniPDP: {
    ...common,
    'data-style-alignment': 'left',
    'data-scroll-to-list': 'false',
    'data-template-id': '54d39695764ea907c0f34825',
    'data-style-height': '34px',
    'data-style-width': '186px',
    'data-no-reviews': 'show',
  },
  // the full text reviews widget for PDPs details tab
  standard: {
    ...common,
    'data-template-id': '5717796816f630043868e2e8',
    'data-fullwidth': 'true',
    'data-style-height': '400px',
    'data-style-width': '100%',
    'data-review-languages': 'it',
  },
  carousel: {
    ...common,
    'data-template-id': '60f537b5b0f1639de1fe048c',
    'data-fullwidth': 'true',
    'data-style-height': '140px',
    'data-style-width': '100%',
    'data-no-reviews': 'show',
    'data-review-languages': 'it',
  },
  reviews: {
    ...common,
    'data-template-id': '544a426205dc0a09088833c6',
    'data-fullwidth': 'true',
    'data-style-height': '700px',
    'data-style-width': '100%',
    'data-no-reviews': 'hide',
    'data-review-languages': 'it',
  },
  getWidgetSettings(type: WidgetType) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const settingsMap: Record<WidgetType, any> = {
      miniTrustPilot: this.miniTrustPilot,
      miniPDP: this.miniPDP,
      standard: this.standard,
      carousel: this.carousel,
      horizontalEssential: this.horizontalEssential,
      reviews: this.reviews,
      mini: this.mini,
    }

    const settings = settingsMap[type]

    if (!settings) {
      throw new Error(`Invalid widget type: ${type}`)
    }

    return settings
  },
}

export default widgetSettings
