export enum PageTypes {
    Home = "Home",
    PDP = "PDP",
    PLP =" PLP",
    InstitutionalPage = "InstitutionalPage",
    Search = "Search",
    Err404 = "404",
    Err500 = "500",
    Account = "Account",
    Checkout = "Checkout",
    Login = "Login",
    Logout = "Logout",
    Carousel = "Carousel",
    Minicart = "Minicart",
    NotSet = ''
  }