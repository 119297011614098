import type { ReactNode } from 'react'
import Alert from 'src/components/common/Alert'
import Footer from 'src/components/common/Footer'
import Navbar from 'src/components/common/Navbar'
import Toast from 'src/components/common/Toast'
import { useUI } from 'src/sdk/ui'

import 'src/styles/pages/layout.scss'
import CartSidebar from './components/cart/CartSidebar'
import TopBarAlert from './components/common/TopBarAlert/TopBarAlert'
import { RenderClient } from './components/RenderClient'
import { SmartBanner } from './components/sections/SmartBanner/SmartBanner'
import { GoogleTagManagerBody } from './components/ThirdPartyScripts/GoogleTagManagerBody'
import { PageTypes } from './typings/pageTypes'

type Props = {
  children: ReactNode | undefined
}

function Layout({ children }: Props) {
  const { displayMinicart } = useUI()

  return (
    <div>
      <GoogleTagManagerBody />
      <div id="layout">
        <SmartBanner />
        <div className="top-bar-alert-max-width-parent">
          <div className="top-bar-alert-max-width">
            <Alert>
              <TopBarAlert />
            </Alert>
          </div>
        </div>
        <Navbar />
        <div className="w-full max-w-[1520px] min-h-[375px] my-0 mx-auto">
          <main>{children}</main>
        </div>
        <div className="footer-max-width-parent ">
          <div className="footer-max-width">
            <Footer />
          </div>
        </div>
        <Toast />
        <RenderClient>{displayMinicart && <CartSidebar />}</RenderClient>
      </div>
    </div>
  )
}

export default Layout
