import axios from 'axios'
import { BK_ID_STORAGE_KEY, Person } from 'src/sdk/session/validate'

// Keys for events and storage items
export const EVENT_QUEUE_KEY = 'abandoned-cart:event-queue'
export const FIRST_TRIGGERED_KEY = 'abandoned-cart:first-triggered'
export const STORAGE_EVENT_KEY = 'abandoned-cart:storage'
export const UPDATED_CART_EVENT_KEY = 'abandoned-cart:updated-cart'
export const DELAY = 1000 * 30

export const notifyUpdate = async (
  person: Person | null,
  orderFormId: string
) => {
  const parsedQueue = JSON.parse(localStorage.getItem(EVENT_QUEUE_KEY) || '[]')
  const bkId = JSON.parse(
    localStorage.getItem(BK_ID_STORAGE_KEY) || '{}'
  )?.bk_id
  const canNotify = person && person.email && person.givenName && bkId
  if (parsedQueue.length <= 0 || !canNotify) return

  try {
    const response = await axios.post('/api/abandonedCart/notifyUpdate', {
      orderFormId,
      bkId,
      email: person.email,
      name: person.givenName,
      isApp: false,
    })
    if (response.status === 200) {
      localStorage.setItem(EVENT_QUEUE_KEY, JSON.stringify([]))
      window.dispatchEvent(new Event(STORAGE_EVENT_KEY))
    }
  } catch (err) {
    console.error(err)
  }
}
